.StepBar {
	position: relative;
	width: 100%;

	font-size: 12px;
	text-align: center;
	font-weight: 700;
}

.StepBar::before {
	content: ' ';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	border-bottom: 1px solid #6e10bb;
}

.Placeholder {
	opacity: 0;
	z-index: 2;
	transition: opacity var(--animation-time-long) ease-in-out;
}

.Show {
	opacity: 1;
	cursor: pointer;
}

.Bar {
	padding-bottom: 4px;

	color: var(--color-accent-1);
	border-bottom: 1px solid var(--color-accent-1);

	transition: all var(--animation-time-long) ease-in-out;
}

.Bar::after {
	content: attr(data-text);
}

.Hide {
	border-bottom: 1px solid rgba(0, 0, 0, 0);
	color: rgba(0, 0, 0, 0);
	z-index: -1;
}
