.TransferOwnership {
	background: var(--background-blue-card-wilder);
	color: white !important;
	padding: 40px;
	width: 640px;
}

.Header {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
	width: 100%;
}

.Header h1 {
	color: white;
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 0;
}

.TransferOwnership hr {
	margin-bottom: 48px;
}

.TransferOwnership h2 {
	font-size: 24px;
	font-weight: 700;
	line-height: 28px;
}

.Section {
	margin-bottom: 56px;
}

.Section > div:nth-of-type(2) {
	margin-left: 12px;
}

.NFT {
	cursor: pointer;
	height: 224px;
	margin-right: 12px;
	min-width: 224px;

	width: 224px;
	background: rgba(0, 0, 0, 0.3);
}

.NFT > div {
	transition: transform var(--animation-time-medium) ease-in-out;
	object-fit: contain;
}

.NFT > img {
	border-radius: inherit;
	height: 100%;
	width: 100%;
}

.NFT:hover > div {
	transform: scale(1.05);
}

.Details {
	margin-left: 12px;
}

.Price {
	margin: 24px 0;
}

.Price div:first-of-type {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.5),
			rgba(255, 255, 255, 0.5)
		),
		linear-gradient(0deg, #808080, #808080);
	-webkit-background-clip: text;
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 4px;
	-webkit-text-fill-color: transparent;
}

.Price span:first-of-type {
	font-size: 18px;
	font-weight: 700;
}

.Price span:nth-of-type(2) {
	font-size: 14px;
	font-weight: 700;
}

.InputWrapper {
	margin-bottom: 40px;
}

.InputWrapper p {
	color: var(--color-primary-lighter-3);
	font-weight: 700;
	margin-bottom: 16px;
	text-align: center;
}

.Inputs {
	width: 100%;
}

.Confirmation {
	background: var(--color-background);
	padding: 32px 40px 40px 40px;
	width: 588px;
}

.Confirmation h2 {
	font-size: 36px;
	font-weight: 700;
	text-align: center;
}

.Confirmation hr {
	margin-bottom: 42px;
	margin-top: 34px;
}

.Confirmation p {
	font-size: 24px;
	font-weight: 700;
	line-height: 28.8px;
	text-align: center;
	max-width: 460px;
	padding-bottom: 0;
	margin: 0 auto 40px;
}

.Confirmation .Buttons {
	display: flex;
	justify-content: center;
}

.Confirmation .Buttons button:first-of-type:not(:last-child) {
	margin-right: 40px;
}
