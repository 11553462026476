.MintNewNFT {
	width: 640px;
	padding: 48px;

	background: var(--background-purple-card-20);
	overflow: hidden;
}

.Blocker {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}

.Header {
	display: flex;
	align-items: center;
	flex-direction: column;

	width: 100%;
}

.Header h1 {
	font-size: 24px;
	font-weight: 700;
	text-align: center;
	overflow-wrap: anywhere;
}

.Header h2 {
	font-size: 16px;
}

.Header > div {
	display: flex;
	align-items: center;
	text-align: center;
	overflow-wrap: anywhere;

	margin-top: 8px;
}

.Header > div > span {
	margin-left: 32px;
}

.Container {
	position: relative;
	height: 542px;
	transition: height var(--animation-time-long) ease-out;
}

.Section {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	padding-top: 80px;
}

.NFT {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 224px;
	width: 224px;
	min-width: 224px;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	border: 1px solid #88d7ff;
	overflow: hidden;

	cursor: pointer;
	transition: transform var(--animation-time-medium) ease-in-out,
		border-color var(--animation-time-medium) ease-in-out;
}

/* .NFT:not(.Uploaded):hover {
	transform: scale(1.03);
} */

.NFT.Uploaded {
	background: rgba(0, 0, 0, 0.3);
	border: none;
	overflow: visible;
	border-radius: 0;
}

.Uploaded:hover > img,
.Uploaded:hover > video {
	transform: scale(1.05);
}

.NFT > img {
	height: 100%;
}

.NFT > img,
.NFT > video {
	max-height: 100%;
	max-width: 100%;
	object-fit: contain;
	transition: transform var(--animation-time-medium) ease-in-out;
}

.Inputs {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: 24px;
}

.Inputs > input:not(:first-child) {
	margin-top: 24px;
}

.Summary {
	display: flex;
}

.Summary h2 {
	font-size: 24px;
}

.Summary li {
	position: relative;
	padding-top: 2px;
	padding-left: 40px;
	overflow-wrap: anywhere;
	margin-top: 8px;
}

.Summary li:not(:first-of-type) {
	margin-top: 16px;
}

.Icon > div {
	content: ' ' !important;
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	height: 24px !important;
	width: 24px !important;
}

.Name {
	font-weight: 700;
}

.Estimate {
	font-weight: 700;
	color: var(--color-primary-lighter-3);
	margin: 16px auto 0 auto;
}

.Error {
	padding: 0;
	font-weight: 700 !important;
	color: var(--color-primary-lighter-3);
}
