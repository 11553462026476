.Container {
	display: flex;
	flex-direction: column;

	width: 400px;
	min-width: 400px;
	max-width: 400px;
	max-height: 80vh;

	background: var(--color-background);

	overflow: hidden;
}

.Header {
	padding: 24px;
	padding-bottom: 0;
}

.Container h4 {
	font-size: 24px;
	font-weight: bold;
	text-align: center;
}

.Container hr {
	margin-top: 24px;
	width: 100%;
}

.Container ul {
	height: 100%;
	max-height: 100%;
	padding-top: 24px;
	padding-bottom: 24px;

	overflow-y: auto;
	overflow-x: visible;
}

.Bid {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px 24px;
	overflow: visible;
}

.Bid button {
	margin-left: auto;
	transition: transform var(--animation-time-short) ease-in-out,
		opacity var(--animation-time-short) ease-in-out;
}

.Bid:hover {
	background: var(--color-background-alt-50);
}

.Bid:hover button {
	transform: scale(1.05);
}

.Bid label {
	font-size: var(--font-size-smaller);
}

.Bid > div {
	display: flex;
	flex-direction: column;
}

.Bid > div > span:first-of-type {
	margin-top: 6px;
	font-weight: bold;
}

.Bid > div > span:not(:first-of-type) {
	margin-top: 6px;
	font-size: 12px;
}
