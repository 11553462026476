.nextDrop {
	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	z-index: 2;

	width: 100%;

	padding: 15px 20px 16px 20px;

	cursor: pointer;
}

.Background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: inherit;
	background: var(--color-cosmic-gradient);
	opacity: 0.25;
	transition: opacity var(--animation-time-medium) ease-in-out;
	z-index: -1;
}

.nextDrop:hover .Background {
	opacity: 0.35;
}

.nextDrop span {
	color: var(--color-text-primary) !important;
}

.nextDrop > img {
	height: 64px;
	width: 64px;

	margin-right: 12px;

	border-radius: 50%;
}

.nextDrop h5 {
	font-size: 24px;
	font-weight: 700;

	color: var(--color-accent-1);
}

.nextDrop h5 > span {
	color: var(--color-baby-blue);
}

.nextDrop p {
	margin-top: 12px;
	padding-bottom: 0;

	font-weight: 700;
}

.nextDrop p > span {
	color: var(--color-accent-1);
}

.Remaining {
	margin-left: 4px;
}

.Bid {
	margin-left: 16px;
	font-weight: 700;
	color: var(--color-accent-blue-100);
}

.Arrow {
	margin-left: 6px;
	margin-top: -20px;
}

@media only screen and (max-width: 1000px) {
	.Bid {
		display: none;
	}
}

@media only screen and (max-width: 400px) {
	.nextDrop {
		font-size: 14px;
	}
}
