.Dropdown {
	position: relative;
}

.Drawer {
	position: absolute;
	top: calc(100% + 8px);
	left: 50%;
	padding: 8px 0;

	width: calc(100% + 58px);

	background: var(--color-purple-dark);
	border: 1px solid #50207b;
	box-shadow: 0px 0px 16px rgba(140, 84, 221, 0.25);

	transform: translateX(-50%);

	z-index: 10;
	overflow: hidden;
}

.Drawer li {
	position: relative;
	padding: 16px 32px;
	cursor: pointer;

	transition: text-shadow var(--animation-time-short) ease-in-out,
		font-weight var(--animation-time-short) ease-in-out;

	&:hover {
		text-shadow: var(--glow-text-white);
		font-weight: 700;
	}
}

.Selected {
	background: rgba(60, 161, 255, 0.05) !important;
}

.Selected::before {
	opacity: 0 !important;
}
