.Container {
	position: relative;
	width: 100%;
	transition: height var(--animation-time-medium) ease;
}

/* Control Styles */

.Controls {
	display: flex;

	width: 100%;
	padding: 15px 16px;

	align-items: center;
}

.Controls .Buttons {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: flex-end;
}

.Controls .Buttons > *:not(:last-of-type) {
	margin-right: 12px;
}

/* List Table Styles */

.Table {
	width: 100%;
}

.Table tbody tr td,
.Table thead th {
	padding: 16px 11px;
	vertical-align: middle;
}

.Table thead th {
	font-size: 14px;
	font-weight: 700;
	color: var(--color-primary-lighter-3);
}

.Table tbody tr td:first-of-type {
	padding-left: 30px;
	padding-right: 0;
}

.Table tbody tr td:last-of-type {
	padding-right: 30px;
	padding-left: 0;
}

.Table tbody tr:last-of-type td:first-of-type {
	border-radius: 0 0 0 var(--box-radius);
}

.Table tbody tr:last-of-type td:last-of-type {
	border-radius: 0 0 var(--box-radius) 0;
}

.Left {
	text-align: left;
}

.Right {
	text-align: right;
}

/* Grid Table Styles */

/* 
  These styles are currently pretty locked down for NFT Cards
	Might want to think about making these more generic in the future
*/
.Grid {
	width: 100%;

	padding: 11px 16px 16px 16px;

	display: grid;
	/* justify-content: space-evenly; */
	grid-template-columns: repeat(auto-fit, minmax(32%, 0.5fr));
	grid-row-start: 1;

	grid-gap: 16px;

	list-style: none;
}

.Grid > div {
	transition: transform var(--animation-time-long) ease;
}

@media only screen and (min-width: 1000px) {
	.Grid > div:hover {
		transform: scale(1.02);
	}
}

@media only screen and (max-width: 950px) {
	.Grid {
		grid-template-columns: repeat(auto-fit, minmax(311px, 1fr));
	}
}

@media only screen and (max-width: 414px) {
	.Container {
		overflow: visible;
	}

	.Grid {
		grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
		// padding: 8px;
	}

	.Controls {
		padding-top: 16px;
		// padding-left: 8px;
		// padding-right: 8px;
	}
}

/* Loading Styles */

.Loading {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;

	padding: 32px 0;

	font-weight: 700;
	font-size: 14px;
}

.Loading div {
	margin-right: 24px;
}

.LoadMore {
	position: absolute;
	right: 0;
	top: calc(100% + 8px);
	font-weight: 400;
}

/* For the sake of v1, I'm just going to hardcode these
	media queries. In the future, they should be set by
	the container rendering the generic table */

@media only screen and (max-width: 1000px) {
	.Table tr td:last-child,
	.Table tr th:last-child {
		display: none;
	}
	.Table tr td:nth-last-child(2),
	.Table tr th:nth-last-child(2) {
		padding-right: 32px;
	}
}

@media only screen and (max-width: 700px) {
	.Controls .Buttons {
		display: none;
	}

	/* Search bar -- @todo select it properly */
	.Controls > div:first-of-type {
		margin-right: 0 !important;
	}
}

@media only screen and (max-width: 1024px) {
	.volume {
		display: none;
	}
}

@media only screen and (max-width: 768px) {
	.lastSale {
		display: none;
	}
}
