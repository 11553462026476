.TextInput {
	width: 100%;
	padding: 12.5px 24px;

	border-radius: 24px;

	color: white;

	background: none;
	transition: border var(--animation-time-medium) ease-in-out;

	&::placeholder {
		color: #dedede;
	}

	&:disabled {
		border-color: var(--color-grey);
		color: var(--color-grey);

		&::placeholder {
			color: var(--color-grey);
		}
	}
}

.Error {
	border: 1px solid var(--color-invalid);
}

.TextInput .ErrorMessage {
	display: block;
	margin-top: 12px;

	color: var(--color-invalid);
	font-size: 14px;
	font-weight: 700;
	text-align: center;
}
