.BannerContainer {
	position: sticky;
	top: 0;
	z-index: 3;
	margin-bottom: 24px;
}

.ScrollDown {
	top: 29px;
	transition: opacity 0.4s ease-in-out, 0.4s ease-in-out;
}

.ScrollUp {
	top: 99px;
	transition: opacity 0.4s ease-in-out, 0.4s ease-in-out;
}
