.Overlay {
	--speed: var(--animation-time-short);

	display: flex;
	justify-content: center;

	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	min-height: 100vh;
	height: 100%;
	overflow-y: auto;

	z-index: 9000;
	opacity: 0;

	background: rgba(0, 0, 0, 0.9) !important;
	transition: backdrop-filter 0.2s;
}

.FullScreen {
	height: 100%;
	width: 100%;
}

.FullScreen .Content {
	width: 100%;
}

.FullScreen > div {
	height: 100%;
}

.Container {
	display: flex;
	justify-content: center;
}

.Centered {
	align-items: center;
}

.Centered > div > div {
	margin: 0 auto;
}

.Image {
	text-align: center;
}

.Overlay > div {
	padding: 64px;
	width: 100%;
}

.Open {
	animation: open var(--speed) ease-in-out;
	opacity: 1;
	/* @todo temporarily disabled backdrop blur for performance */
	/* backdrop-filter: blur(30px) opacity(1); */
}

.Closed {
	animation: close var(--animation-time-short) ease-in-out;
	opacity: 0;

	/* @todo temporarily disabled backdrop blur for performance */
	/* backdrop-filter: blur(30px) opacity(0); */
}

.Close {
	position: fixed;
	top: 32px;
	right: 32px;
	background: rgba(255, 255, 255, 0.1);
}

@keyframes open {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes close {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@media only screen and (max-width: 768px) {
	.Overlay > div {
		padding: 0 !important;
	}
}
