.Container {
	padding: 16px;

	&:first-of-type {
		padding: 32px 24px;
	}

	&:not(:first-of-type) {
		margin-top: 24px;
	}
}

.Header {
	display: flex;
	align-items: center;

	h1 {
		margin-left: 24px;
		font-size: 36px;
		font-weight: 700;
	}

	img {
		border-radius: 50%;
	}
}

.History {
	padding: 20px 0 20px 0;
	margin-top: 24px;

	h4 {
		margin-bottom: 4px;
		font-size: 12px;
		color: #bfbfbf;
		padding: 0 20px;
	}

	ul {
		padding: 0 20px;

		li {
			display: flex;
			justify-content: space-between;
			padding: 16px 0;
		}
	}

	span {
		padding: 20px;
		padding-top: 0;
		font-weight: 400;
	}

	.Loading {
		display: flex;
		align-items: center;
		margin-top: 19px;
		margin-bottom: -3px;
	}
}

.HistoryNoBids {
	padding: 20px 0 0 0;
}
