.NFTCard {
	cursor: pointer;
	min-width: 280px;
	/* max-width: 388px; */

	background-color: var(--color-background-alt);

	@media only screen and (max-width: 414px) {
		min-width: unset;
	}
}

.Body {
	padding: 16px;
}

.Creator {
	display: flex;
	align-items: center;

	color: white;
}

.Creator > div {
	height: 32px;
	width: 32px;

	background: var(--background-purple-card);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	border-radius: 50%;
}

.Creator > span {
	margin-left: 8px;
}

.NFTCard h5 {
	margin-bottom: 1px;
	color: white;
	font-size: 24px;
	font-weight: 700;
}

.NFT {
	height: 450px;
	width: 100% !important;
	border-radius: var(--box-radius) var(--box-radius) 0 0;
	background: rgba(0, 0, 0, 0.3);

	img,
	video {
		width: 100%;
	}
}

.Foot {
	display: flex;
	justify-content: space-between;
	margin-top: 32px;
}

.Foot > div {
	display: flex;
	flex-direction: column;
}

.Foot > div:not(:first-child) > span {
	margin-left: 40px;
}

.Foot > div > span:first-child {
	color: var(--color-primary-lighter-3);

	text-transform: uppercase;
}

.Foot > div > span:last-child {
	margin-top: 8px;

	font-size: 24px;
	color: white;
}

.HasAspectRatio {
	position: relative !important;

	.NFT {
		height: auto !important;
		position: absolute !important;
		top: 0;
		left: 0;
	}

	&.Ratio16-9 {
		padding-top: 56.25%;
	}

	&.Ratio1-1 {
		padding-top: 100%;
	}

	&.Ratio4-5 {
		padding-top: 125%;
	}
}
