.Container {
	width: 100%;
	padding: 16px;
	margin-top: 16px;

	> * {
		&:not(:first-child) {
			margin-top: 24px;
		}
	}

	.Links {
		padding: 8px 16px;

		> a {
			color: var(--color-grey-lighter-2);
			font-size: 24px;
			font-weight: 700;

			transition: color var(--animation-time-short) ease-in-out;

			&:not(:first-of-type) {
				margin-left: 40px;
			}

			&.Active {
				color: var(--color-text-primary);
				text-shadow: var(--glow-text-white) !important;
			}

			&:hover {
				text-shadow: none;
			}
		}
	}
}
