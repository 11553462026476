.Bar {
	position: relative;
	width: 100%;
}

.Bar ul {
	display: flex;
}

.Tab button {
	font-size: 18px;
	font-weight: 700;
	color: var(--color-grey-lighter-2);
	background: none;
	/* transition: font-weight var(--animation-time-medium) ease-in-out; */
}

.Selected button {
	color: white;
	text-shadow: var(--glow-text-white);
	transition: text-shadow var(--animation-time-medium) ease-in-out,
		color var(--animation-time-short) ease-in-out;
}

.Tab:not(:last-child) {
	margin-right: 40px;
}

.Bar hr {
	margin-top: 4px;
}

.Slider {
	position: absolute;
	top: calc(100% - 1px);
	height: 0px;
	box-shadow: 0px 0px 5px 0px var(--color-accent-2);
	border: 1px solid #e0baff;
	z-index: 1;
	left: 0;
	transition: left var(--animation-time-medium) ease-in-out,
		width var(--animation-time-medium) ease-in-out;
}
