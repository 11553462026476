.Button {
	margin: 40px auto 0 auto;
}

.Green {
	color: var(--color-success);
}

.Error {
	display: inline-block;
	width: 100%;
	margin-top: 40px;
	color: var(--color-error);
	text-align: center;
}

.Terms {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 40px;
}

.Terms * {
	cursor: pointer;
}

.Terms input {
	opacity: 0;
	position: absolute;
}

.Terms div {
	position: relative;
	height: 16px;
	width: 16px;
	border: 1px solid var(--color-accent-1);
	border-radius: 4px;
	transition: border-color var(--animation-time-short) ease;
}

.Terms .Selected {
	border-color: green;
	background-color: #58c57310;
}

.Terms div::after {
	content: ' ';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url('./assets/check.svg');
	background-repeat: no-repeat;
	background-position: center;
	opacity: 0;
	transition: opacity var(--animation-time-short) ease;
}

.Terms .Selected::after {
	opacity: 1;
}

.Terms button {
	font-weight: 400;
}

.Terms label {
	margin-left: 8px;
}
