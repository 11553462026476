.Dots {
	--dot-size: 5px;
	--dot-gap: 2.8px;

	position: relative;
	height: 32px;
	width: 16px;
	cursor: pointer;
}

.Dots > div {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: var(--dot-size);
	width: var(--dot-size);
	border-radius: 50%;
	background: var(--color-accent-1);
}

.Dots > div:first-child {
	transform: translate(-50%, calc(-50% - calc(100% + var(--dot-gap))));
}

.Dots > div:last-child {
	transform: translate(-50%, calc(-50% + calc(100% + var(--dot-gap))));
}

.Wilder {
	position: fixed;
	top: 29px;
	height: 54px;
	width: 54px;

	z-index: 4;

	display: flex;
	justify-content: center;
	align-items: center;

	background: linear-gradient(180deg, #1c4358 0%, #2a0047 100%);
	border-radius: 50%;

	transform: translateX(calc(-100% - 19px));

	cursor: pointer;

	transition: transform var(--animation-time-medium) ease-in-out;
}

.Wilder img {
	max-width: calc(65% - 1px);
	height: auto;
	margin-left: 1px;

	user-drag: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
