:root {
	--height: 236px;
}

.PreviewCard {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
}
/* marginTop: 16, marginLeft: 43, marginRight: 43, marginBottom: 0 */
.PreviewCard hr {
	margin: 16px 43px 0px 43px;
}

.Preview {
	display: flex;
	width: 100%;
	padding: 16px 16px;

	@media only screen and (max-width: 950px) {
		flex-direction: column;
	}
}

.Loading {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.Spinner {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border-top: 2px solid var(--color-accent-1);
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.Asset {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 400px;
	height: 225px;
	cursor: pointer;
	border-radius: 8px;
	overflow: hidden;

	@media (hover: hover) and (pointer: fine) {
		&:hover > div {
			transform: scale(1.02);
		}
	}

	@media only screen and (max-width: 950px) {
		width: 100%;
		padding-left: 8px;
		padding-right: 8px;
	}

	@media only screen and (max-width: 415px) {
		padding-left: 0;
		padding-right: 0;
	}

	.Image > img {
		width: 100%;
		height: 100%;
	}

	div {
		overflow: hidden;
		transition: transform var(--animation-time-medium) ease-in-out;
		border-radius: 16px;
		z-index: 2;
	}
}

.MVP3Asset {
	border-radius: 9px 0px 0px 0px;
}

.InfoContainer {
	display: flex;
	flex: 1;
	height: 224px;
	padding: 8px 24px 0 24px;

	@media only screen and (max-width: 1280px) {
		flex-direction: column;
		height: unset;
	}

	@media only screen and (max-width: 950px) {
		padding: 8px 8px 0 8px;
	}

	@media only screen and (max-width: 415px) {
		padding-left: 0;
		padding-right: 0;
	}

	.Body {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: space-between;
		align-self: center;
		width: 100%;

		padding-right: 24px;

		@media only screen and (max-width: 1280px) {
			padding-right: 0;
			flex-grow: 0;
		}

		h5 {
			color: white;
			font-size: 36px;
			font-weight: 700;

			/* white-space: nowrap; */
			overflow: hidden;
			text-overflow: ellipsis;

			@media only screen and (max-width: 550px) {
				font-size: 24px;
			}
		}

		.Description {
			margin-top: 16px;
			color: white;
			line-height: 19.2px;
			padding-bottom: 0;

			-ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
			scrollbar-width: none; /* Hide scrollbar in Firefox */
			display: -webkit-box;
			-webkit-line-clamp: 8;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;

			@media only screen and (max-width: 1280px) {
				-webkit-line-clamp: 6;
			}

			@media only screen and (max-width: 950px) {
				-webkit-line-clamp: unset;
			}
		}

		.Description::-webkit-scrollbar {
			display: none; /* Hide scrollbar in Chrome */
		}
	}

	.Buy {
		display: flex;
		flex-direction: column;
		justify-content: center;

		margin-left: auto;
		margin-right: 40px;

		@media only screen and (max-width: 1280px) {
			margin: 0;

			align-items: flex-start;
		}

		@media only screen and (max-width: 600px) {
			display: none;
		}

		.ViewLink {
			margin-top: 16px;
			padding: 0;
			font-size: 16px;
			font-weight: 400;
			color: var(--color-accent-blue-100);
			cursor: pointer;
		}

		.ViewLink:hover {
			color: var(--color-accent-1);
			text-shadow: var(--glow-text-blue), var(--glow-text-blue);
		}

		* {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		span {
			&:first-of-type {
				margin-top: 20px;
				font-size: 24px;
			}

			&:last-of-type {
				margin-top: 8px;
				color: var(--color-primary-lighter-3);
			}
		}
	}
}

.Domain {
	color: var(--color-accent-1);
}

.Members {
	display: flex;
	justify-content: space-between;
	margin-top: 16px;
}

.Members > div {
	width: 50%;
	display: flex;
	align-items: center;
}

.Dp {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	background-color: var(--color-accent-1-lighter);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.Member {
	margin-top: 12px;
	margin-left: 16px;
	font-weight: 700;
}

.Member > span:last-of-type {
	color: var(--color-primary-lighter-3);
	margin-top: 4px;
}

.Children {
	padding: 24.5px 8px 26px 8px;
}

.Blocker {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 5;
}

// @media only screen and (max-width: 1000px) {
// 	.Bid {
// 		display: none;
// 	}
// }

// @media only screen and (max-width: 768px) {
// 	.InfoContainer {
// 		flex-direction: column;
// 		padding-bottom: 16px;
// 	}

// 	.Asset {
// 		width: 326.5px;
// 		height: 437px;
// 		background: none;
// 	}

// 	.Body {
// 		justify-content: flex-start;
// 		margin-right: 0;
// 	}

// 	.Description {
// 		margin-top: 40px !important;
// 		max-height: 76px;
// 	}

// 	.Buy {
// 		margin: 0 auto;
// 	}
// }

// @media only screen and (max-width: 710px) {
// 	.Members {
// 		flex-direction: column;
// 	}

// 	.Members > *:not(:first-child) {
// 		margin-top: 16px;
// 	}
// }

// @media only screen and (max-width: 660px) {
// 	.Members > div:not(:first-child) > div {
// 		/* margin-left: auto; */
// 	}

// 	.Buy {
// 		margin: 0 auto;
// 	}

// 	.View {
// 		margin-top: 8px !important;
// 	}
// }

// @media only screen and (max-width: 600px) {
// 	.Preview {
// 		flex-direction: column;
// 	}

// 	.Description {
// 		margin-top: 16px !important;
// 	}

// 	.Body {
// 		padding: 16px;
// 		margin: 0;
// 		margin-top: 24px;
// 	}

// 	.Asset {
// 		width: 100%;
// 		height: auto;
// 		max-height: 437px;
// 		border-radius: var(--box-radius);
// 	}

// 	.Asset * {
// 		max-height: 437px;
// 		height: auto;
// 		border-radius: var(--box-radius);
// 	}

// 	.Members {
// 		margin-top: 24px;
// 		flex-direction: row;
// 		justify-content: flex-start;
// 	}

// 	.Members > * {
// 		margin-top: 0 !important;
// 		width: auto !important;
// 	}

// 	.Members > *:not(:first-child) {
// 		margin-left: 56px;
// 	}
// }
