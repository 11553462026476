.Container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 32px;
}

.Status {
	display: inline-block;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 14px;
	margin-top: 4px;
}

.Amount {
	display: inline-block;
	font-weight: 700;
	font-size: 24px;
	margin-top: 4px;
}

.Date {
	display: inline-block;
	font-size: 12px;
	margin-top: 4px;
}

.Button {
	max-width: 200px;
	margin-top: 24px;
	text-transform: uppercase;
}
