.Button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	padding: 0 24px;

	background: var(--color-background-alt);

	color: white;

	border-radius: var(--box-radius);
}

.Button > img {
	height: 18px;
	width: 18px;
	margin-right: 7px;
}

.Button > span {
	display: inline-block;
	white-space: nowrap;
}
