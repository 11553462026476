.Message {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 16px;

	background: var(--color-purple-lighter);

	.Content {
		flex: 1;
		margin-left: 16px;
	}
}
