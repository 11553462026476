header {
	/* Not sure if it's an absolute or static header */
	z-index: 10;

	width: 100%;
	min-width: var(--width-max);
	max-width: var(--width-max);

	/* Just here to illustrate the actual size of the header */
	/*border-bottom: 1px solid black;*/
}

/* Nav Alignments */

.TitleBar {
	position: relative;
	display: flex;
	flex-direction: column;

	z-index: 4;

	width: 100%;

	background: var(--color-background);
	border-radius: var(--box-radius);

	overflow: none;

	transition: background-color var(--animation-time-medium) ease-in-out,
		border var(--animation-time-medium) ease-in-out,
		box-shadow var(--animation-time-medium) ease-in-out;
}

.Title {
	margin-left: 16px;
}

.Hovered {
	/* background-color: rgba(0, 63, 137, 0.4); */
	box-shadow: 0 0 20px 0 rgb(235 0 255 / 40%);
}

.Searching {
	/* background-color: rgba(0, 63, 137, 0.4); */
	box-shadow: 0 0 20px 0 rgb(235 0 255 / 40%);
}

.Bar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 17px;
}

.Bar::after {
	content: ' ';
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	height: 100%;
	width: 100%;

	border-radius: var(--box-radius);
	/* border: 1px solid #AF4CFF; */
	box-shadow: var(--glow-pimary);
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

.Searching .Bar > div:nth-child(2) {
	display: none;
}

.Searching .Search {
	flex: 1;
	max-width: 100%;
}

/* Search Styling */

.Navigation {
	flex: 1;
	flex-grow: 1;
	display: flex;
	align-items: center;
	height: 32px;
}

.Buttons {
	display: flex;
}

.Navigation > input {
	background: none;
	margin-left: 16px;
	margin-top: 3px;

	/* border-bottom: 1px solid transparent; */
	/* transition: border-bottom var(--animation-time-medium) ease-in-out; */
}

.Navigation > input:hover {
	background: none;
	/* border-bottom: 1px solid var(--color-accent-1); */
}

.Navigation > input::placeholder {
	color: #e5baff70;
}

.Searching {
	border-radius: var(--box-radius);
	box-shadow: none;
}

.Searching .Navigation > input {
	/* border-bottom: 1px solid var(--color-accent-1); */
	transition: none;
	width: 100%;
}

.Search {
	flex: 1;
	margin-left: 16px;

	background: none;
	color: white;
	font-weight: bold;
}

.Search::placeholder {
	font-weight: 400;
}

.SearchResults {
	width: calc(100% + 2px);
	/* background: inherit; */
	overflow: hidden;

	border-top: 0px solid white !important;
	border-radius: 0 0 var(--box-radius) var(--box-radius);
	box-shadow: none;

	overflow-y: auto;
	max-height: 80vh;
}

.SearchResults ul {
	width: 100%;
}

.SearchResults li {
	width: 100%;
	padding: 17px 17px 17px 103px;

	cursor: pointer;

	/* transition: all var(--animation-time-short) ease-in-out; */
}

.SearchResults li span {
	display: inline-block;
	margin-left: 8px;
	font-size: 14px;
	color: var(--color-accent-1);
}

.SearchResults li:hover {
	background: var(--background-purple-card-20);
}

.SearchResults .ExactMatch {
	font-weight: bold;
}

.GrowContainer {
	transition: height var(--animation-time-long) ease-in-out;
}

.ZNA {
	flex-shrink: 0;
}

.Actions {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 56px;
}

.Actions > *:not(:last-child) {
	margin-right: 16px;
}

.Info {
	margin-right: 16px;
	font-weight: 700;
	text-transform: uppercase;
	flex-shrink: 0;
}

@media only screen and (max-width: 1000px) {
	.Actions {
		display: none !important;
	}

	.Info {
		margin-right: 0;
	}

	.TitleBar {
		padding: 11px 17px;
	}
}

@media only screen and (max-width: 768px) {
	.ZNA {
		margin-left: 0 !important;
	}

	.Buttons {
		display: none !important;
	}

	.Search {
		min-width: 0px;
		margin-left: 0 !important;
	}

	.SearchResults li {
		display: flex;
		flex-direction: column;
		padding-left: 17px;
	}

	.SearchResults li span {
		margin: 0;
		margin-top: 4px;
	}
}

@media only screen and (max-width: 500px) {
	.Search {
		display: none;
	}
}
