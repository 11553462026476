.Container {
	width: 100%;
}

.Image {
	width: 100%;
	height: 296px;
	border-radius: var(--box-radius);
	object-fit: cover;
}

.Available {
	margin-top: 24px;
	margin-bottom: 24px;
}

.Available span {
	font-size: 12px;
	color: var(--color-grey);
}

.Available h2 {
	font-weight: 700;
}

.Button {
	margin: 25px auto 0 auto;
}

.Green {
	color: var(--color-success);
}

.Orange {
	color: var(--color-warning);
}

.Checking {
	display: flex;
	justify-content: center;
	align-items: center;
}

.Checking span {
	margin-left: 16px;
}
