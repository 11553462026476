.Container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	min-height: 40px;
	margin-top: 16px;
}

.Bid {
	display: flex;
	flex-direction: column;
}

.Bid label {
	font-size: 12px;
	color: var(--color-primary-lighter-3);
}

.Crypto {
	margin-top: 2px;
	font-size: 18px;
	color: var(--color-text-primary);
}

.Fiat {
	font-size: 14px;
	color: var(--color-grey);
	margin-top: 2px;
}

@media only screen and (max-width: 1000px) {
	.Container button {
		display: none;
	}
}
