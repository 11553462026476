.Spinner {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border-top: 2px solid var(--color-accent-1);
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
