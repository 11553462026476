.profile {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding: 54px;
	color: #4d4d4d;
	background: var(--background-blue-deck);
	filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.5));
	max-width: var(--width-max);
	margin: 0 auto;
}

.profile h1 {
	font-size: 24px;
	font-weight: 700;
	color: white;
}

.AcceptRequest {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.header {
	padding-left: 60px;
	padding-top: 32px;
	padding-bottom: 16px;

	font-size: 36px;
	color: white;

	border-radius: var(--box-radius) var(--box-radius) 0 0;

	/* background: linear-gradient(92.24deg, rgba(0, 22, 55, 0.5) 0%, rgba(0, 63, 137, 0.265) 100%); */
}

.body {
	display: flex;
	/* justify-content: center; */
	padding: 40px 52px 40px 0;

	font-size: var(--font-size-default);
	color: white;
}

.name {
	font-size: 24px;
	font-weight: 700;

	color: white;
}

.Sections > button {
	transition: border-bottom var(--animation-time-medium) ease-in-out;
}

.dp {
	width: 160px;
	height: 160px;

	margin: 0 auto;

	border-radius: 50%;

	filter: drop-shadow(0px 0px 40px rgba(255, 255, 255, 0.5));

	object-fit: cover;
}

.endpoint {
	margin-top: 16px;

	color: white;
}

/* DP section */
.First {
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 176px;
}

/* Profile paragraph */
.Second {
	width: 550px;

	line-height: 22px;
}

.body > div:nth-child(2) > p {
	margin-top: 16px;
}

.Sections {
	position: relative;
	display: flex;

	width: 100%;

	font-size: 24px;
	color: white;
}

.Sections::after {
	content: ' ';
	position: absolute;
	top: 0.25px;
	left: 0;
	width: 100%;
	height: 100%;

	border-bottom: 1px solid #6e10bb;
	z-index: -1;
}

.Sections button {
	font-size: 24px;
	transition: border-bottom var(--animation-time-medium) ease-in-out;
}

.Sections button:not(:first-child) {
	margin-left: 40px;
}
