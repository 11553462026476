.Header {
	display: flex;
	align-items: center;

	h1 {
		margin-left: 24px;
		font-size: 36px;
		font-weight: 700;
	}
}

.Stats {
	display: flex;
	margin-top: 16px;

	&:first-of-type {
		margin-top: 32px;
	}

	> * {
		flex: 1;
		max-width: 33%;

		&:not(:last-child) {
			margin-right: 16px;
		}
	}
}
