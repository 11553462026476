.Container {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	> * {
		&:not(:first-child) {
			margin-top: 40px;
		}
	}

	p {
		padding: 0;
	}
}

.Buttons {
	display: flex;
	justify-content: center;

	> * {
		&:not(:first-of-type) {
			margin-left: 24px;
		}
	}
}
