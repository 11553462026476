.TransferPreview {
	background: radial-gradient(
			56.93% 56.93% at 50% 50%,
			rgba(206, 183, 255, 0) 0%,
			rgba(103, 79, 255, 0.2) 100%
		),
		linear-gradient(0deg, #000000, #000000);
	padding: 27.5px 40px;
	width: 552px;
}

.TransferPreview h4 {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 19px;
	text-align: center;
}

.TransferPreview li > div {
	display: flex;
	margin-top: 34px;
	margin-bottom: 34px;
}

.TransferPreview li hr {
	margin-bottom: 42px;
}

.Image {
	cursor: pointer;
	height: 200px;
	overflow: hidden;
	transition: transform var(--animation-time-medium) ease-in-out;
	width: 200px;
}

.Image:hover {
	transform: scale(1.05);
}

.Image img {
	height: 100%;
	object-fit: contain;
	width: 100%;
}

.Info {
	display: flex;
	flex-direction: column;
	margin-left: 32px;
	max-width: 240px;
	position: relative;
	width: 240px;
	word-wrap: break-word;
}

.Info h5 {
	font-size: 24px;
	margin-bottom: 12px;
}

.Info .Address {
	font-size: 14px;
	color: var(--color-primary-lighter-3);
	font-weight: 700;
}

.Info div:last-of-type {
	line-height: 19px;
	margin-top: auto;
}

.Info p {
	padding-bottom: 18px;
}

.Info a {
	margin-bottom: 12px;
}
