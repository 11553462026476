.NavBar {
	position: fixed;
	top: 29px;
	margin-left: 0;
	margin-right: 0;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	border-radius: var(--box-radius);
	z-index: 10;

	transition: opacity 0.4s ease-in-out, 0.4s ease-in-out;
	transform: translateY(0);
}

.Hidden {
	opacity: 0;
	transform: translateY(-100px);
}
