.WildPriceContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	background: none;
	height: 100%;
	padding: 4px 16px;
	cursor: pointer;
	z-index: 0;
	transition: all 0.2s ease-in-out 0s;

	&::after {
		content: '';
		z-index: -1;
		position: absolute;
		height: 100%;
		left: 0;
		top: 0;
		width: 99%;
		transform: scale(1, 0.56);
		border-left: 1px solid var(--color-purple-lighter);
		border-right: 1px solid var(--color-purple-lighter);
	}

	&:hover::after {
		transform: scale(1);
		background: var(--color-azure-gradient-25);
	}
}

.CurrentPriceContainer {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}

.TickerName {
	font-size: 10px;
	font-weight: 400;
	line-height: 12.1px;
	color: var(--color-grey-lighter-2);
}

.CurrentPriceSml {
	font-size: 12px;
	font-weight: bold;
	line-height: 14.5px;
	padding: 2px 6px 0 0;
	color: var(--color-text-primary);
	text-shadow: var(--glow-text-blue);
}

.CurrentPriceLrg {
	font-size: 16px;
	font-weight: bold;
	line-height: 24px;
	padding: 0 6px 0 0;
	color: var(--color-text-primary);
	text-shadow: var(--glow-text-blue);
}

.PercentageChange {
	font-size: 12px;
	font-weight: 400;
	line-height: 14.5px;
}

.PercentageChangePositive {
	color: var(--color-success);
}

.PercentageChangeNegative {
	color: var(--color-error);
}

.DropdownContentContainer {
	display: none;
	position: absolute;
	top: 56%;
	right: calc(0% - 50px);
	padding: 32px;
	z-index: 1;
}

.WalletConnected {
	right: calc(0% - 130px);
}

.DropdownContent {
	width: 535px;
	cursor: default;
	padding: 32px;
	border-radius: 16px;
	background: var(--color-purple-darkest);
}

.WildPriceContainer:hover .DropdownContentContainer {
	display: block;
}

.ExternalUrl {
	display: flex;
	align-items: flex-end;

	a {
		font-size: 12px;
		line-height: 15px;
		text-shadow: none;
		background-color: transparent;
		padding-right: 6px;
		color: var(--color-grey-lighter-4);
	}

	&:hover {
		transform: scale(1.01);
	}
}

.Section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.DividerCopy {
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	margin-top: 24px;
	color: var(--color-grey-lighter-3);
}

.Divider {
	width: 82%;
	margin-top: 10px;
	border-top: 1px solid var(--color-purple-lighter);
}

.UrlRowContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;

	a {
		max-width: 140px;
		width: 100%;
		font-size: 14px;
		line-height: 17px;
		text-shadow: unset;
		color: var(--color-accent-blue-100);
		padding-top: 10px;
	}
}

.IconWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 8px;

	p {
		padding: 2px 0 6px 0;
	}

	&:hover {
		background: var(--color-azure-gradient-25);
	}
}
