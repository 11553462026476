.Row {
	cursor: pointer;
	/* transition: background var(--animation-time-short) linear; */
}

.Row:hover {
	background: var(--color-background-alt-50);
}

.Row td:first-of-type {
	width: 50px;
}

.Right {
	text-align: right;
}

.Right > * {
	margin-left: auto;
}

.Left {
	text-align: left;
}

.Left > * {
	margin-right: auto;
}

.Bid {
	display: block;
}

.Bid:not(:first-child) {
	margin-top: 2px;
	color: var(--color-grey);
	font-size: 12px;
}

// .volumeCol {
// 	@media only screen and (max-width: 768px) {
// 		display: none;
// 	}
// }

@media only screen and (max-width: 1024px) {
	.volumeCol {
		display: none;
	}
}

@media only screen and (max-width: 768px) {
	.lastSaleCol {
		display: none;
	}
}
