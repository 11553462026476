.Row {
	cursor: pointer;

	&:hover {
		background: var(--color-background-alt-50);
	}

	td {
		&:first-of-type {
			padding-left: 8px !important;
			width: 40px;
			text-align: center;
		}

		&:last-of-type {
			display: flex;
			justify-content: center;
			padding-right: 0px !important;
		}
	}
}

.Right {
	text-align: right;

	& > * {
		margin-left: auto;
	}
}

.Left {
	text-align: left;

	& > * {
		margin-right: auto;
	}
}
.Dots {
	--dot-size: 5px;
	--dot-gap: 2.8px;

	position: relative;
	height: 32px;
	width: 16px;
	cursor: pointer;
	background: none;

	> div {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: var(--dot-size);
		width: var(--dot-size);
		border-radius: 50%;
		background: var(--color-accent-1);

		&:first-child {
			transform: translate(-50%, calc(-50% - calc(100% + var(--dot-gap))));
		}

		&:last-child {
			transform: translate(-50%, calc(-50% + calc(100% + var(--dot-gap))));
		}
	}
}
