/* Mobile First */
.Container {
	width: 100%;
	position: relative;
	border-radius: var(--box-radius);
	overflow: hidden;
	z-index: 2;
	transition: box-shadow var(--animation-time-medium) ease-in-out;
	transition: filter var(--animation-time-medium) ease-in-out;
	transition: transform var(--animation-time-medium) ease-in-out;

	background: var(--color-purple-lighter);
	padding: 0;
	text-align: left;

	margin: 16px 0;
}

.Mobile {
	display: block;
	@media only screen and (min-width: 900px) {
		display: none;
	}
}

.Container:hover {
	transform: scale(1.01);
}

.Background {
	display: flex;
	min-width: 100%;
	min-height: 100%;
	right: -150px;

	background-repeat: no-repeat;
	background-size: contain;
	background-position: 80%;

	opacity: 0.5;
	position: absolute;
}

.Content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;

	padding: 26px 16px;

	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0.35) 0%,
		rgba(0, 0, 0, 0) 51.04%
	);

	position: relative;
}

.Content:hover {
	cursor: pointer;
}

/* Text */
.Title {
	background: var(--color-cosmic-gradient);
	background-clip: text;
	-webkit-text-fill-color: transparent;

	font-family: var(--font-family);
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
}

.Label {
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 12px;
	padding-bottom: 0;
	margin-top: 4px;

	color: white;
}

/*Button*/
.Button {
	font-family: var(--font-family);
	color: var(--color-accent-blue-100);
	background: none;
	z-index: 2;
	transition: text-shadow var(--animation-time-medium) ease-in-out;
	padding-bottom: 0;
	margin-top: 8px;

	font-weight: 700;
	font-size: 16px;

	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.Button img {
	margin-left: 6px;
}

/* PC Resolution */
@media only screen and (min-width: 768px) {
	.Content {
		display: flex;
		flex-direction: row;
		padding: 37px 40px;
	}

	.Title {
		font-size: 18px;
		line-height: 21px;
	}

	.Label {
		font-size: 14px;
		margin-top: 4px;
	}

	.Button {
		top: 50px;
		right: 40px;
		bottom: auto;
		left: auto;
	}

	.Background {
		opacity: 0.6;
		right: 0;
	}
}

@media only screen and (min-width: 900px) {
	.Background {
		opacity: 0.7;
		background-position: 70%;
	}
}
