.DomainTableContainer {
	width: 100%;
	background: var(--background-purple-card-20);
	overflow: hidden;
}

.DomainTable {
	position: relative;
}

.Expander {
	transition: height var(--animation-time-long) ease-in-out;
}

.Empty {
	text-align: center;
}

.DomainTable {
	width: 100%;
}

.Table {
	width: 100%;
}

.Container {
	position: absolute;
	left: 0;
	width: 100%;
}

/* Search header */
.searchHeader {
	display: flex;

	width: 100%;
	padding: 15px 20px;

	align-items: center;
}

.searchHeaderButtons {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: flex-end;
}

.searchHeaderButtons > *:not(:last-of-type) {
	margin-right: 12px;
}

.search {
	flex-grow: 1;
}

/* Table Header */

.DomainTable th {
	color: var(--color-primary-lighter-3);
	font-weight: 700;
	font-size: var(--font-size-smaller);
}

/* Table */
.DomainTable tbody {
	font-weight: 500;
}

.DomainTable th,
.DomainTable td {
	padding: 16px 11px;
	vertical-align: middle;
}

.DomainTable th:first-child,
.DomainTable td:first-child {
	padding-left: 30px;
	padding-right: 0;
	width: 50px;
}

.DomainTable th:last-child,
.DomainTable td:last-child {
	padding-right: 30px;
}

.DomainTable > tbody > tr {
	cursor: pointer;
	color: white;
	transition: background var(--animation-time-short) linear;
}

.DomainTable > tbody > tr:hover {
	background: var(--color-background-alt-50);
}

.DomainTable > tbody > tr img {
	transition: transform var(--animation-time-medium) ease-in-out;
}

.DomainTable > tbody > tr:hover img,
.DomainTable > tbody > tr:hover video {
	transform: scale(1.1);
}

.ticker {
	color: var(--color-accent-1-light);
	font-weight: 700;
}

.positive {
	color: var(--color-green-2);
}

.negative {
	color: var(--color-red);
}

.positive > div,
.negative > div {
	content: ' ';
	width: 0;
	height: 0;

	margin-top: 4px;
	margin-right: 4px;
}

.positive > div {
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid var(--color-green-2);
}

.negative > div {
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid var(--color-red);
}

/* Table alignments */
.left {
	text-align: left;
}

.center {
	text-align: center;
}

.center > * {
	margin: 0 auto;
}

.right {
	text-align: right;
}

.right > * {
	float: right;
}

.Loading {
	padding: 32px;
}

.Loading > div {
	margin: 0 auto;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border-top: 2px solid var(--color-accent-1);
	animation: spin 1s linear infinite;
}

.Empty {
	padding: 32px 32px 40px 32px;
	text-align: center;
	font-weight: 700;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* Grid */

.Grid {
	width: 100%;
	padding: 11px 16px 24px 16px;

	display: grid;
	/* justify-content: space-evenly; */
	grid-template-columns: repeat(auto-fit, minmax(32%, 0.5fr));
	grid-row-start: 1;

	grid-gap: 1.5rem;

	list-style: none;
}

.Grid > li {
	cursor: pointer;

	transition: transform var(--animation-time-medium) ease-in-out;
}

@media (hover: hover) and (pointer: fine) {
	.Grid > li:hover {
		transform: scale(1.03);
	}
}

@media only screen and (max-width: 1000px) {
	.Global tr td:last-child,
	.Global tr th:last-child {
		display: none;
	}
	.Global tr td:nth-last-child(2),
	.Global tr th:nth-last-child(2) {
		padding-right: 32px;
	}
}

@media only screen and (max-width: 900px) {
	.Global tr td:nth-last-child(2),
	.Global tr th:nth-last-child(2) {
		display: none;
	}

	.Global tr td:nth-last-child(3),
	.Global tr th:nth-last-child(3) {
		padding-right: 32px;
	}
}

@media only screen and (max-width: 768px) {
	.Grid {
		grid-template-columns: repeat(auto-fit, minmax(311px, 1fr));
	}
}

@media only screen and (max-width: 700px) {
	.searchHeader > div:first-child {
		margin-right: 0px !important;
	}

	.searchHeaderButtons {
		display: none;
	}
}

@media only screen and (max-width: 414px) {
	.DomainTableContainer {
		background: none;
		border: none;
		box-shadow: none;
		overflow: visible;
	}

	.searchHeader {
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
	}

	.Grid {
		padding: 0;
	}
}
