.Container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Container p {
	font-size: 16px;
	font-weight: 700;
	padding: 0;
	margin-bottom: 16px;
}
