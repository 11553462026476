.Link {
	position: relative;
}

.Link:hover .ArrowContainer {
	margin-left: 3px;
}

.Link:hover .Back {
	margin-left: -3px;
}

.Link:hover .ArrowContainer::after {
	box-shadow: 0px 0px 10px 3px var(--color-accent-1);
}

.ArrowContainer {
	position: absolute;
	left: calc(100% + 5px);
	top: 0;
	height: 100%;
	width: 12px;

	transition: margin-left var(--animation-time-medium) ease-in-out;
}

.ArrowContainer::after {
	content: ' ';
	position: absolute;
	top: 50%;
	left: 50%;
	height: 0px;
	width: 0px;
	background: rgba(0, 0, 0, 0);

	transform: translate(-60%, -50%);

	transition: box-shadow var(--animation-time-short) ease-in-out;
}

.Arrow {
	height: 1px;
	width: 8px;
	position: absolute;
	left: 0;
	top: 50%;

	background: var(--color-accent-1);
}

.Arrow::after,
.Arrow::before {
	content: ' ';
	position: absolute;
	right: -1px;
	height: 1px;
	width: 6px;
	background: inherit;
}

.Arrow::after {
	transform: translateY(-200%) rotate(45deg);
}

.Arrow::before {
	transform: translateY(200%) rotate(-45deg);
}

.Back {
	left: calc(100% + 8px);
}

.Back .Arrow {
	transform: rotate(180deg);
}

/* .Arrow::after {
    content: ' ';
    position: absolute;
    height: 1px;
    width: 100%;
    background: black;
    transform: translateY(2px);
} */
