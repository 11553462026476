.Request {
	display: flex;

	max-width: 1250px;
	min-height: 542px;
	max-height: 542px;

	padding: 8px;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.Error {
	padding: 0;
	font-weight: 700 !important;
	color: var(--color-primary-lighter-3);
}

.Image {
	width: 543px;
	max-width: 543px;
	border-radius: var(--box-radius);
	cursor: pointer;
	background: rgba(0, 0, 0, 0.3);
}

.Image:hover > * {
	transform: scale(1.01);
}

.Image > * {
	transition: transform var(--animation-time-medium) ease-in-out;
	height: 100%;
	width: 100%;
	object-fit: contain !important;
}

.Info {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	padding: 32px 48px;
}

.Info h1 {
	font-size: 36px;
	font-weight: 700;
}

.Info > div {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
}

.Info > h1 {
	font-size: 36px;
}

.Domain {
	display: inline-block !important;
	margin-top: 8px;
}

.Story {
	max-height: 150px;
	padding-right: 12px;
	margin-top: 39px;
}

.Offer {
	margin-top: 8px;
	text-align: center;
}

.Offer > span {
	display: inline;
}

.Offer > span:first-of-type {
	font-size: 40px;
	font-weight: 700;
}

.Offer > span:last-of-type {
	font-size: 24px;
	color: var(--color-primary-lighter-3);
	margin-left: 16px;
}

.Buttons {
	display: flex;
	justify-content: center;
	margin-top: 32px;
}

.Buttons > button:not(:first-child) {
	margin-left: 16px;
}

.Confirmation {
	width: 588px;
	padding: 32px 40px 40px 40px;
	background: var(--color-background);
}

.Confirmation h2 {
	font-size: 36px;
	font-weight: 700;
	text-align: center;
}

.Confirmation hr {
	margin-top: 34px;
	margin-bottom: 42px;
}

.Confirmation p {
	font-size: 24px;
	font-weight: 700;
	line-height: 28.8px;
	text-align: center;
}

.Confirmation .Buttons {
	justify-content: center;
}

.FulfillIndicator > div > p {
	font-size: 24px !important;
}

@media only screen and (max-width: 1200px) {
	.Info {
		width: 50%;
	}

	.Image {
		width: 40%;
	}
}

@media only screen and (max-width: 860px) {
	.Request {
		flex-direction: column;
	}

	.Request > div {
		width: 100%;
	}

	.Image {
		max-width: 100%;
		width: 100%;
	}

	.Offer > span {
		font-size: 24px !important;
	}
}

@media only screen and (max-width: 650px) {
	.Image {
		max-height: 200px;
	}
}
