.Container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	margin-bottom: 40px;

	h1 {
		padding: 5.5px 0;
		font-size: 24px;
		font-weight: 700;
	}

	hr {
		width: 100%;
		margin-top: 18px;
		border: 0;
		height: 0;
		border-top: 1px solid var(--color-purple-lighter);
	}
}
