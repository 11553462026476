.Container {
	width: 640px;
	padding: 40px;
}

.Content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	> * {
		&:not(:first-child) {
			margin-top: 40px;
		}
	}

	p {
		padding: 0;
	}
}
