.Container {
	> * {
		&:not(:first-child) {
			margin-top: 24px;
		}
	}

	.Back {
		padding: 0;
		background: none;
		color: var(--color-text-primary);

		img {
			margin-right: 8px;
			height: 24px;
			width: 24px;
		}
	}
}
