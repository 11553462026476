.MintPreview {
	width: 552px;
	padding: 27.5px 40px;

	max-height: 70vh;

	overflow: auto;

	background: radial-gradient(
			56.93% 56.93% at 50% 50%,
			rgba(206, 183, 255, 0) 0%,
			rgba(103, 79, 255, 0.2) 100%
		),
		linear-gradient(0deg, #000000, #000000);
}

.MintPreview > h4 {
	margin-bottom: 19px;
	font-size: 24px;
	font-weight: 700;
	text-align: center;
}

.MintPreview > li {
	display: flex;
	margin-top: 34px;
	margin-bottom: 34px;
}

.Image {
	height: 200px;
	width: 200px;

	overflow: hidden;
}

.Image img {
	height: 100%;
	width: 100%;

	object-fit: contain;
}

.Info {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 240px;
	max-width: 240px;
	margin-left: 32px;
	padding-top: 5px;
}

.Info h5 {
	font-size: 24px;
}

.Info a {
	margin-top: 12px;
	overflow-wrap: break-word;

	font-weight: 700;
}

.Info span {
	overflow-wrap: break-word;
}

.Info p {
	padding: 0;

	line-height: 19px;
}

.Info p:first-of-type {
	max-height: 70px;
	margin-top: 12px;

	overflow-y: auto;
}
