.textButton {
	color: var(--color-accent-1);
	font-weight: 700;

	display: flex;
	align-items: center;

	padding-top: 3px;
	padding-bottom: 0;

	background-color: rgba(0, 0, 0, 0);

	transition: color var(--animation-time-short) ease-in-out,
		text-shadow var(--animation-time-short) ease-in-out;
}

.textButton:hover {
	text-shadow: var(--glow-text-blue);
}

.selected {
	color: white;
	text-shadow: var(--glow-text-white) !important;
}
