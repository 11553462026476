.Container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.Container span {
	display: inline-block;
	width: 100%;
	margin-left: 12px;
	margin-bottom: 40px;

	color: var(--color-error);
	text-align: center;

	line-height: 24px;
}
