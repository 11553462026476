/* Mobile First */
.Container {
	width: 100%;
	position: relative;
	border-radius: var(--box-radius);
	overflow: hidden;
	z-index: 2;
	transition: box-shadow var(--animation-time-medium) ease-in-out;
	transition: filter var(--animation-time-medium) ease-in-out;
	transition: transform var(--animation-time-medium) ease-in-out;
	background: var(--color-azure-gradient-100);
}

.Mobile {
	display: block;
	@media only screen and (min-width: 900px) {
		display: none;
	}
}

.Container:hover {
	transform: scale(1.01);
}

.Content {
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 16px 0;
	background: var(--color-black-gradient-40);
}

.Content:hover {
	cursor: pointer;
}

.Label {
	font-family: var(--font-family);
	font-weight: 400;
	font-size: 16px;
	margin-right: 8px;
	padding-bottom: 0;
	color: var(--color-white-secondary);
}

.Countdown {
	font-weight: 700;
	font-size: 14px;
	margin-right: 8px;
	padding-bottom: 0;
	color: var(--color-white-secondary);
}

.Button {
	font-family: var(--font-family);
	color: var(--color-accent-blue-100);
	background: none;
	z-index: 2;
	transition: text-shadow var(--animation-time-medium) ease-in-out;
	padding-bottom: 0;

	font-weight: 700;
	font-size: 14px;

	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.Button img {
	margin-left: 6px;
}

/* PC Resolution */
@media only screen and (min-width: 768px) {
	.Content {
		display: flex;
		flex-direction: row;
	}

	.Label {
		font-size: 16px;
	}

	.Countdown {
		font-size: 16px;
	}

	.Button {
		font-size: 16px;
		top: 50px;
		right: 40px;
		bottom: auto;
		left: auto;
	}
}
