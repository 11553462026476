.Tooltip {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 400px;
	padding: 2.5px 16px 5px 16px;
	font-weight: 700;
	color: #ffffff;
	background-color: var(--color-purple-lighter);
	box-shadow: 0px 0px 16px rgba(140, 84, 221, 0.25);
	border-radius: 8px;
	z-index: 10000;
}

.Arrow path {
	fill: var(--color-purple-lighter);
}
