.Artwork {
	display: flex;
}

.Pending * {
	cursor: default;
}

.Image {
	height: 40px;
	width: 40px;
	min-height: 40px;
	min-width: 40px;
	border-radius: 8px;
	margin-right: 12px;
	overflow: hidden;
	cursor: pointer;
	transition: transform var(--animation-time-medium) ease-in-out;

	&.Circle {
		border-radius: 50%;
	}
}

.Domain {
	display: block;
	font-size: var(--font-size-smaller);
	color: var(--color-grey-lighter-2);
}

.Image:hover {
	transform: scale(1.1);
}

.Image > * {
	height: 100%;
	width: 100%;
}

.Image img,
.Image video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.Info {
	display: flex;
	justify-content: center;
	flex-direction: column;
	max-width: inherit;
}

.Info > *:first-child {
	color: white;
	font-weight: 400;
}

.Info > *:not(:first-child) {
	margin-top: 2px;
}

.Title {
	display: block;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.Domain {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
